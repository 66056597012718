import React from "react"
// import React, { useState, useEffect, useRef } from "react"
// import { Trans } from "gatsby-plugin-react-i18next"
// import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import "bootstrap/dist/css/bootstrap.min.css"
import "./SlideSection.scss"
import ArticleSlide from "../Article/ArticleForSlide"

import neurondImg from "../../assets/img/NeurondWhite.png"
import ourGoalBG from "../../assets/img/OurGoal/background.jpg"
import rightEffect from "../../assets/img/OurGoal/RightEffect.png"
import leftEffect from "../../assets/img/OurGoal/LeftEffect.png"

import slide1 from "../../assets/img/OurGoal/wesolveproblems.jpg"
import slide2 from "../../assets/img/OurGoal/bestinthebusiness.jpg"

// import { Carousel } from "react-responsive-carousel"
// import "react-responsive-carousel/lib/styles/carousel.min.css"

// import ReactScrollWheelHandler from "react-scroll-wheel-handler"

// import Slider from "react-slick"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

const ourGoal = {
  subject: "OUR GOAL",
  description: "Positively Impact The World With Artificial Intelligence",
}

const slideData = [
  {
    title: "We Solve Problems",
    description: [
      "Every organization has a unique mountain to climb. We are hyper-focused on developing artificial intelligence and machine learning solutions that will help you reach the peak.",
    ],
    imgLink: slide1,
  },
  {
    title: "The Best In The Business",
    description: [
      "When you work with us, you are partnering with a team with over 15 years of experience in creating state-of-the-art technical solutions. We have unrivalled expertise in data science and artificial intelligence. We harness that knowledge to push your business to new heights.",
    ],
    imgLink: slide2,
  },
]

export default function SlideSection() {
  // const sliderRef = useRef()
  // const parentRef = useRef(null)

  // const [selectedItem, setSelectedItem] = useState(0)
  // useEffect(() => {
  //   if (!parentRef.current) {
  //     return
  //   }
  //   parentRef.current.addEventListener("wheel", e => handleScroll(e))

  //   return () => {
  //     parentRef.current.removeEventListener("wheel", e => handleScroll(e))
  //   }
  // }, [selectedItem, parentRef])

  // const settings = {
  //   arrows: false,
  //   infinite: false,
  //   swipe: false,
  //   speed: 400,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   // rows: 2,
  //   // slidesPerRow: 2,
  //   // centerMode: true,
  //   // centerPadding: "20%",
  // }

  // function handleScroll(e) {
  //   if (e.deltaY > 0) {
  //     sliderRef && sliderRef.current.slickNext()
  //   } else if (e.deltaY < 0) {
  //     sliderRef && sliderRef.current.slickPrev()
  //   }
  // }

  return (
    <section className="slide-section">
      <div
        className="firstLayer"
        style={{ backgroundImage: `url(${neurondImg})` }}
      ></div>
      <div
        className="secondLayer d-flex justify-content-between flex-wrap"
        style={{ backgroundImage: `url(${ourGoalBG})` }}
      >
        <img className="secondLayer__left-image" src={leftEffect} alt="Left effect" />
        <img className="secondLayer__right-image" src={rightEffect} alt="Right effect" />
      </div>
      <div
        className="thirdLayer"
        // ref={parentRef}
      >
        <div className="wrapper">
          <div className="thirdLayer__slideSection slide-ins">
            {/* carousel ----------------------------------------- */}
            {/* <Carousel
                  showThumbs={false}
                  showIndicators={false}
                  autoPlay={false}
                  showStatus={false}
                  showArrows={false}
                  selectedItem={selectedItem}
                >
                  {slideData.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="thirdLayer__slideSection__articleSection align-left">
                          <Article
                            title={item.title}
                            description={item.description}
                            color={false}
                          />
                        </div>

                        <img
                          className="thirdLayer__slideSection__pic float-left"
                          src={item.imgLink}
                          alt={item.title}
                        />
                      </div>
                    )
                  })}
                </Carousel> */}

            {/* slider------------------------------ */}
            {/* <Slider {...settings} ref={sliderRef} className="custom-slider">
              {slideData.map((item, index) => {
                return (
                  <div key={index} className="slider">
                    <div className="thirdLayer__slideSection__articleSection align-left">
                      <Article
                        title={item.title}
                        description={item.description}
                        color={false}
                      />
                    </div>

                    <img
                      className="thirdLayer__slideSection__pic float-left"
                      src={item.imgLink}
                      alt={item.title}
                    />
                  </div>
                )ArticleSlide
              })}
            </Slider> */}
            {slideData.map((item, index) => {
              return (
                // <div
                //   className="thirdLayer__slideSection__articleSection align-left"
                // >
                <ArticleSlide
                  title={item.title}
                  description={item.description}
                  color={false}
                  imgLink={item.imgLink}
                  key={index}
                />
                //</div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="wrapper fourthLayer">
        <div className="fourthLayer__slideToArticle">
          {slideData.map((item, index) => {
            return (
              <div key={index}>
                <ArticleSlide
                  title={item.title}
                  description={item.description}
                  index={index}
                  imgLink={item.imgLink}
                  color={false}
                  showSpecialType={true}
                />
              </div>
            )
          })}
        </div>
        <div className="fourthLayer__topicSection">
          <p className="fourthLayer__topicSection__subject">
            {ourGoal.subject}
          </p>
          <h2 className="fourthLayer__topicSection__description">
            {ourGoal.description}
          </h2>
        </div>
      </div>
    </section>
  )
}

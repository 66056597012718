import React from "react"
import PropTypes from "prop-types"
// import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import "./ArticleForSlide.scss"

ArticleForSlide.propTypes = {
  subject: PropTypes.string,
  title: PropTypes.string.isRequired,
  isShowLine: PropTypes.bool,
  description: PropTypes.string,
  color: PropTypes.bool,
  // index: PropTypes.number,
  imgLink: PropTypes.string,
  // showSpecialType: PropTypes.bool,
  // showArticleImgHidden: PropTypes.bool,
}

ArticleForSlide.defaultProps = {
  isShowLine: true,
  color: true,
  showSpecialType: false,
  showArticleImgHidden: false,
}

export default function ArticleForSlide(props) {
  const {
    title,
    isShowLine,
    description,
    // index,
    subject,
    color,
    imgLink,
    // showSpecialType,
    // showArticleImgHidden,
  } = props

  // const { t } = useTranslation()
  return (
    <div className="article-slide margin-bot">
      <div className="row custom">
        <div className="article-slide__textBox">
          {subject && (
            <HandleOrphan>
              {" "}
              <h2
                className=" fix-orphan article-slide__textBox__subject"
                style={{
                  color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                }}
              >
                {subject}
              </h2>
            </HandleOrphan>
          )}
          {title && (
            <HandleOrphan>
              <h2
                className=" fix-orphan article-slide__textBox__title"
                style={{
                  color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                }}
              >
                {title}
              </h2>
            </HandleOrphan>
          )}
          {isShowLine && <div className="article-slide__textBox__line"></div>}
          {description && (
            <HandleOrphan>
              <p
                className=" fix-orphan article-slide__textBox__description"
                style={{
                  color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                }}
              >
                {description}
              </p>
            </HandleOrphan>
          )}
        </div>

        {imgLink && (
          <div className="article-slide__imgBox ">
            <div className="article-slide__imgBox__picBox">
              <img className="changePosition" src={imgLink} alt={title} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
